/* src/components/Cart.css */
.mobile-cart-container {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: white;
    border-top: 1px solid #ddd;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
    z-index: 1000; /* Ensure the mobile cart container is above other elements */
  }
  
  .mobile-cart-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: #f8f9fa;
  }
  
 
  .mobile-cart-details {
        padding: 10px;
        max-height: 50vh; /* Set a maximum height for the cart details */
        overflow-y: auto; /* Enable vertical scrolling */
  }

  .mobile-cart-scroll {
    max-height: 40vh; /* Adjust the height as needed */
    overflow-y: auto;
  }    
  